
"use strict";

(function() {

    var Webcam = require('../vendor/WebcamJS-1.0.6.js'),
        Croppie = require('croppie'),

        // Webcam preview
        elem = document.getElementById('webcam'),
        preview_image = document.getElementById('preview_image'),
        final_image = document.getElementById('final_image'),

        // Buttons
        start_btn = document.getElementById('start_btn'),
        retake_btn = document.getElementById('retake_btn'),
        submit_btn = document.getElementById('submit_btn'),
        back_btn = document.getElementById('back_button'),

        // Countdown & feedback
        countdown_overlay = document.getElementById('countdown_overlay'),
        upload_msg = document.getElementById('upload-msg'),
        pre_msg = document.getElementById('instruction-msg'),
        crop_msg = document.getElementById('crop-msg'),

        // Form elements
        form = document.getElementById('form'),
        file = document.getElementById('file'),

        // Internal vars
        counter,
        counterReset = 3,
        timer = null;

    // Check for the existence of all required document elements
    if (
        elem === null ||
        start_btn === null ||
        submit_btn === null ||
        retake_btn === null ||
        countdown_overlay === null ||
        preview_image === null ||
        form === null ||
        file === null
    ) {
        return;
    }

    // Try to get the counter value from the config
    if (elem.getAttribute('data-counter') !== null) {
        counterReset = parseInt(elem.getAttribute('data-counter'))
    }
    counter = counterReset;

    /**
     *
     */
    var croppie_image = new Croppie(preview_image, {
        viewport: { width: 500, height: 500 },
        boundary: { width: 720, height: 720 },
        showZoomer: false
    });

    /**
     * Note: The 3rd party WebCam script indeed has it's own freeze method.
     *       Unfortunately that one unfreezes as soon as you take a snapshot,
     *       which is necessary to obtain the base64 string we need to send to the server.
     *
     * Our custom solution uses an img overlay to show the preview.
     * Also, upon showing the preview the two buttons to either retake or accept the image are revealed.
     */
    function freeze()
    {
        Webcam.snap(function(data_uri){

            preview_image.setAttribute('src', data_uri);

            croppie_image.bind({
                url: data_uri
            });

            if (crop_msg !== null) {
                crop_msg.classList.remove('hidden');
            }

            elem.classList.add('hidden');
            preview_image.classList.remove('hidden');

            // show the follow up buttons
            retake_btn.classList.remove('hidden');
            submit_btn.classList.remove('hidden');
        });
    }

    /**
     * Hide all interface buttons.
     * This is necessary upon taking the picture (during the countdown period),
     * as well as during the uploading phase. We do not want visitors to (re)take an image at those times.
     */
    function hideAllButtons()
    {
        start_btn.classList.add('hidden');
        retake_btn.classList.add('hidden');
        submit_btn.classList.add('hidden');
    }

    /**
     * Count down from 3 to 1 before taking a shot.
     *
     * Handles showing the countdown to the visitor and takes the shot once the timer is done.
     * Also cleans things up and resets the timer.
     *
     */
    function countDown()
    {
        if (counter === 0) {

            countdown_overlay.classList.add('hidden');
            countdown_overlay.innerHTML = '';

            counter = counterReset;

            freeze();
        } else {

            countdown_overlay.innerHTML = counter;
            countdown_overlay.classList.remove('hidden');

            counter--;

            if (timer !== null) {
                clearTimeout(timer);
            }

            timer = setTimeout(countDown, 1000);
        }
    }

    /**
     * Starts the countdown
     *
     * Before doing so it clears up the interface and prepares the webcam.
     *
     * @param e
     */
    function startPictureCountDown(e)
    {
        e.preventDefault();

        hideAllButtons();

        // Upon starting the first count-down we hide the back button, if there is one
        if (back_btn !== null) {
            back_btn.classList.add('hidden');
        }

        if (pre_msg !== null) {
            pre_msg.classList.add('hidden');
        }

        // If necessary
        Webcam.unfreeze();

        preview_image.classList.add('hidden');
        elem.classList.remove('hidden');

        // Count down
        countDown();
    }

    /**
     * Upload the previewed image through the html form.
     * Show a feedback message while the image is uploading
     *
     * @param e
     */
    function upload(e)
    {
        e.preventDefault();

        hideAllButtons();

        if (crop_msg !== null) {
            crop_msg.classList.add('hidden');
        }

        if (upload_msg !== null) {
            upload_msg.classList.remove('hidden');
        }

        //on button click
        croppie_image.result('canvas').then(function(base64Image) {

            if (final_image !== null) {
                final_image.setAttribute('src', base64Image);

                preview_image.classList.add('hidden');
                final_image.classList.remove('hidden');
            }

            // There may be a maximum upload / process time before a redirect triggers
            if (
                countdown_overlay.getAttribute('data-max') !== null &&
                countdown_overlay.getAttribute('data-url') !== null
            ) {
                setTimeout(function(){
                    window.location = countdown_overlay.getAttribute('data-url');
                }, parseInt(countdown_overlay.getAttribute('data-max')) * 1000);
            }

            file.setAttribute('value', base64Image);
            form.submit();
        });
    }




    // Configure the webcam. These settings allow the use of a 720p webcam.
    Webcam.set('width', 1280);
    Webcam.set('height', 720);

    Webcam.set('crop_width', 720);
    Webcam.set('crop_height', 720);

    Webcam.set('jpeg_quality', 100);
    Webcam.set('image_format', 'jpeg'); // Expected by php

    // Mirror mode
    Webcam.set('flip_horiz', true);

    // Reveal the camera button once the webcam is ready
    Webcam.on('live', function(){

        start_btn.addEventListener('click', startPictureCountDown, false);
        start_btn.classList.remove('hidden');

        retake_btn.addEventListener('click', startPictureCountDown, false);
        submit_btn.addEventListener('click', upload, false);
    });

    // Here to avoid alert() popups
    // TODO: Rollbar integration?
    Webcam.on('error', function(err) {});

    // Kick-off
    Webcam.attach(elem);
}());